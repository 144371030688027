import request from '@/utils/request'


// 查询游记评审团列表
export function listNotesReview(query) {
  return request({
    url: '/study/notesReview/list',
    method: 'get',
    params: query
  })
}

// 查询游记评审团分页
export function pageNotesReview(query) {
  return request({
    url: '/study/notesReview/page',
    method: 'get',
    params: query
  })
}

// 查询游记评审团详细
export function getNotesReview(data) {
  return request({
    url: '/study/notesReview/detail',
    method: 'get',
    params: data
  })
}

// 新增游记评审团
export function addNotesReview(data) {
  return request({
    url: '/study/notesReview/add',
    method: 'post',
    data: data
  })
}

// 修改游记评审团
export function updateNotesReview(data) {
  return request({
    url: '/study/notesReview/edit',
    method: 'post',
    data: data
  })
}

// 删除游记评审团
export function delNotesReview(data) {
  return request({
    url: '/study/notesReview/delete',
    method: 'post',
    data: data
  })
}
