<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-collapse v-model="activeKey">
        <a-collapse-panel key="1" :header="$t('游记内容')" class="goods-collapse">
      <a-form-model-item prop="content" >
        <span slot="label" >
          <a-tooltip>
            {{$t('内容')}}<template slot="title">{{$t('内容')}}</template>
          </a-tooltip>
        </span>
        <a-textarea v-model="form.content" placeholder="请输入" disabled="false"/>
<!--        <input type="textarea"  v-model="form.content" />-->
      </a-form-model-item>

      <a-form-item
        v-if="form.type == '1'"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="视频">
        <template >
          <video controls width="320" height="240">
            <source :src="form.videoUrl" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </template>
      </a-form-item>
      <a-form-model-item prop="award" >
        <span slot="label" >
          <a-tooltip>
            {{$t('奖励')}}<template slot="title">{{$t('奖励')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.award" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="archives" >
        <span slot="label" >
          <a-tooltip>
            {{$t('档案')}}<template slot="title">{{$t('档案')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.archives" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
        </a-collapse-panel>

      <a-collapse-panel key="2" :header="$t('评审团')" class="goods-collapse">
        <a-row>

          <a-col :span="5">
            <a-form-model-item prop="score" >
                <span slot="label" >
                <a-tooltip>
                {{$t('评分')}}<template slot="title">{{$t('评分')}}</template>
                </a-tooltip>
                </span>
              <a-input-number :min="0" :max="10" v-model="form.score" />
            </a-form-model-item>
          </a-col>

          <a-col :span="9">
            <a-form-model-item prop="reviewChairman" >
                <span slot="label" >
                <a-tooltip>
                {{$t('评审团主席')}}<template slot="title">{{$t('评审团主席')}}</template>
                </a-tooltip>
                </span>
              <a-select :placeholder="$t('请选择')+$t('评审团主席')" v-model="form.reviewChairman"  allowClear>
                <a-select-option v-for="(item, index) in reviewList" :value="item.id"
                                 :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="10">
            <a-form-model-item prop="reviewMember" >
                <span slot="label" >
                <a-tooltip>
                {{$t('评审团成员')}}<template slot="title">{{$t('评审团成员')}}</template>
                </a-tooltip>
                </span>
<!--              <a-select :placeholder="$t('请选择')+$t('评审团成员')" v-model="form.reviewMember"  allowClear>-->
<!--                <a-select-option v-for="(item, index) in reviewList2" :value="item.id"-->
<!--                                 :key="index">-->
<!--                  {{ item.name }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->


                <a-select
                  mode="multiple"
                  v-model="reviewMemberList"
                  placeholder="请选择"
                >
                  <a-select-option v-for="(d, index) in reviewList2" :key="index" :value="d.id">
                    {{ d.name }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-col>

        </a-row>

        <a-form-model-item prop="opinion" >
        <span slot="label" >
          <a-tooltip>
            {{$t('评审团意见')}}<template slot="title">{{$t('评审团意见')}}</template>
          </a-tooltip>
        </span>
          <a-input v-model="form.opinion" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
        </a-form-model-item>
      </a-collapse-panel>
      </a-collapse>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTravelNotes, addTravelNotes, updateTravelNotes } from '@/api/study/travelNotes'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";

import Editor from '@/components/Editor'
import Video from "@/views/video/index.vue";
import {listNotesReview} from "@/api/study/notesReview";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Video,
    CustomDictTag,
    Editor
  },
  data () {
    return {
      reviewMemberList:[],
      reviewList:[],
      reviewList2:[],
      activeKey: [1, 2, 3, 4],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        content: null,

        videoUrl: null,
        reviewChairman: null,

        applyId: null,

        studyId: null,

        award: null,

        archives: null,

        createTime: null,
        opinion: null,
        score: null,
        reviewMember: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        award: [
          { required: true, message: this.$t('奖励')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ], archives: [
          { required: true, message: this.$t('档案')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ], opinion: [
          { required: true, message: this.$t('评审团意见')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],score: [
          { required: true, message: this.$t('评分')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],reviewChairman: [
          { required: true, message: this.$t('评审团主席')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getReviewList()
    this.getReviewList2()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        content: null,
        videoUrl: null,
        reviewChairman: null,
        opinion: null,
        score: null,
        reviewMember: null,
        applyId: null,
        studyId: null,
        award: null,
        archives: null,
        createTime: null,
        remark: null
      }

    },

    getReviewList(){
      listNotesReview({"type":1}).then(response => {
        this.reviewList = response.data
      })
    },
    getReviewList2(){
      listNotesReview({"type":2}).then(response => {
        this.reviewList2 = response.data
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTravelNotes({"id":id}).then(response => {
        this.reviewMemberList=response.data.reviewMember.split(',')
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {

      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          this.form.reviewMember = this.reviewMemberList.join(',')
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateTravelNotes(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addTravelNotes(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script >
<style lang="less">
.goods-collapse > .ant-collapse-header {
  color: #007ffd !important;
  background: #e8eef9;
}
</style>