import request from '@/utils/request'


// 查询游记游学视频列表
export function listTravelNotes(query) {
  return request({
    url: '/study/travelNotes/list',
    method: 'get',
    params: query
  })
}

// 查询游记游学视频分页
export function pageTravelNotes(query) {
  return request({
    url: '/study/travelNotes/page',
    method: 'get',
    params: query
  })
}

// 查询游记游学视频详细
export function getTravelNotes(data) {
  return request({
    url: '/study/travelNotes/detail',
    method: 'get',
    params: data
  })
}

// 新增游记游学视频
export function addTravelNotes(data) {
  return request({
    url: '/study/travelNotes/add',
    method: 'post',
    data: data
  })
}

// 修改游记游学视频
export function updateTravelNotes(data) {
  return request({
    url: '/study/travelNotes/edit',
    method: 'post',
    data: data
  })
}

// 删除游记游学视频
export function delTravelNotes(data) {
  return request({
    url: '/study/travelNotes/delete',
    method: 'post',
    data: data
  })
}
