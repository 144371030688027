<template>
  <a-drawer width="70%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="name" >
        <span slot="label" >
          <a-tooltip>
            {{$t('研学活动名称')}}<template slot="title">{{$t('研学活动名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.name" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('研学活动名称')" />
      </a-form-model-item>



      <a-form-model-item :label="this.$t('研学分类')" prop="studyClassify" >
        <a-select  style="width: 100%" v-model="form.studyClassify" placeholder="请选择研学分类" allowClear>
          <a-select-option v-for="(item, index) in studyClassifyList" :value="item.id" :key="index">
            {{ item.classifyName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>




      <a-form-model-item :label="this.$t('活动主图')" prop="picture">
        <a-upload
            name="picture"
            listType="picture-card"
            :multiple="false"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="coverImgUpload">
          <img
              v-if="form.picture"
              :src="form.picture"
              alt="picture"
              style="height: 100px; width: 100px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
          </div>
        </a-upload>
<!--        <span>推荐尺寸 100 x 100</span>-->
      </a-form-model-item>

      <a-form-model-item prop="videoUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('视频')}}<template slot="title">{{$t('视频')}}</template>
          </a-tooltip>
        </span>
        <OssUploadSingle v-model="form.videoUrl" type="video" :max-count="1" :prefix="study" :maxSize="200"></OssUploadSingle>
      </a-form-model-item>


<!--      <a-form-model-item :label="$t('轮播图')" prop="imgList">-->
<!--        <a-upload-->
<!--            name="imgList"-->
<!--            v-model="imgList"-->
<!--            listType="picture-card"-->
<!--            :fileList="imgList"-->
<!--            :remove="removeImgCaseFile"-->
<!--            class="avatar-uploader"-->
<!--            :multiple="false"-->
<!--            :show-upload-list="true"-->
<!--            @preview="handlePreview"-->
<!--            :before-upload="beforeOssUpload"-->
<!--            :customRequest="coverImgCaseUploadInfo">-->
<!--          <div v-if="imgList.length < 9">-->
<!--            <a-icon :type="loading ? 'loading' : 'plus'"/>-->
<!--            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>-->
<!--          </div>-->
<!--        </a-upload>-->
<!--        <span>推荐尺寸 375 x 375</span>-->
<!--      </a-form-model-item>-->

      <a-form-model-item prop="isPay" >
        <span slot="label" >
          <a-tooltip>
            {{$t('是否免费')}}<template slot="title">{{$t('是否免费')}}</template>
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.isPay" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>


      <a-form-model-item :label="$t('活动价格')" v-if="form.isPay==0" prop="price">
        <a-input v-model="form.price" @change="limitPrice()" suffix="¥"
                 :placeholder="$t('通用.输入.请输入')+$t('活动价格')"/>
      </a-form-model-item>


<!--      <a-form-model-item prop="content" >-->

<!--        <editor v-model="form.configValue" />-->
<!--        -->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('详情')}}<template slot="title">{{$t('详情')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.content" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->



      <a-form-model-item label="详情"   prop="content">
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item prop="address" >
        <span slot="label" >
          <a-tooltip>
            {{$t('活动地址')}}<template slot="title">{{$t('活动地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.address" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('活动地址')" />
      </a-form-model-item>

<!--      <a-form-model-item prop="startTime" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('研学活动.开始时间')}}<template slot="title">{{$t('研学活动.开始时间')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.startTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="endTime" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('研学活动.结束时间')}}<template slot="title">{{$t('研学活动.结束时间')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.endTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->

      <a-form-model-item :label="$t('活动记录.开始时间')" prop="startTime" >
        <a-date-picker style="width: 100%" v-model="form.startTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <a-form-model-item :label="$t('活动记录.结束时间')" prop="endTime" >
        <a-date-picker style="width: 100%" v-model="form.endTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>


      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('研学活动.备注')}}<template slot="title">{{$t('研学活动.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getStudy, addStudy,isStudy ,updateStudy } from '@/api/study/study'
import { listClassify } from '@/api/study/classify'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import OssUploadSingle from "@/components/OssUploadSingle";

import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    OssUploadSingle,
    CustomDictTag,
    Editor
  },
  data () {
    return {
      studyClassifyList:[],
      statusOptions:[
        {label:"收费",value:0},
        {label:"免费",value:1}

      ],
      imgList: [],
      isStudy: true,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,
        imgList: [],
        name: null,
        studyClassify: null,

        picture: null,

        imgs: null,

        price: null,

        content: null,
        videoUrl: null,

        address: null,
        type: 1,

        isPay: 0,

        startTime: null,

        endTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        studyClassify: [{ required: true, message: '请选择研学分类', trigger: 'blur' }],
        picture: [{ required: true, message: '请输入活动主图', trigger: 'blur' }],
        imgList: [{ required: true, message: '请上传轮播图', trigger: 'blur' }],
        isPay: [{ required: true, message: '请选择付费状态', trigger: 'blur' }],
        price: [{ required: true, message: '请输入活动价格', trigger: 'blur' }],
        content: [{ required: true, message: '请输入活动详情', trigger: 'blur' }],
        videoUrl: [{ required: true, message: '请上传视频', trigger: 'blur' }],
        address: [{ required: true, message: '请输入活动地址', trigger: 'blur' }],
        startTime: [{ required: true, message: '请输入活动开始时间', trigger: 'blur' }],
        endTime: [{ required: true, message: '请输入活动结束时间', trigger: 'blur' }],
      }
    }
  },
  filters: {
  },
  created () {
    this.getStudyClassify()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    getStudyClassify(){
      listClassify({}).then(response => {
        this.studyClassifyList = response.data
      })
    },


    limitPrice() {
      let val = this.form.price.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.price = val
    },

    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'study'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'picture', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束

    //多图上传开始
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    // 上传详情覆盖默认的上传行为
    coverImgCaseUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'study'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.imgList.push({status: 'done', url: res, uid: this.getUidRandom(), name: res})
          console.log(_this.imgList)
          this.form.imgList = []
          _this.imgList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.imgList.push(e.url);
            }
          })
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeImgCaseFile(file) {
      var fileList = []
      this.imgList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var imgList = []
      this.imgList = fileList
      fileList.forEach(e => {
        imgList.push(e.url)
      })
      this.form.imgList = imgList
    },
    //多图上传结束



    onClose () {
      this.open = false
      this.form = {
        imgList: []
      }

      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.imgList=[]
      this.form = {
        id: null,
        name: null,
        studyClassify: null,
        picture: null,
        imgs: null,
        imgList: [],
        price: null,
        content: null,
        videoUrl: null,
        address: null,
        type: 1,
        isPay: 0,
        startTime: null,
        endTime: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      isStudy().then(response => {
        if (!response.data){
          this.isStudy=false
          this.$message.error("本店铺没有发布研学活动权限,请联系平台授权发布权限")
          return
        }
        this.reset()
        this.formType = 1
        this.open = true
        this.formTitle = this.$t('通用.文本.添加')
      })


    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getStudy({"id":id}).then(response => {

        this.form = response.data

        //回显出轮播图
        this.imgList =[];
        var advertImgList = this.form.imgList ==null ? [] : this.form.imgList;
        for (var i = 0; i < advertImgList.length; i++) {
          this.imgList.push({status: 'done', url: advertImgList[i], uid: this.getUidRandom(), name: advertImgList[i]})
        }
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateStudy(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addStudy(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
