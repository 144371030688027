<template>
  <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="type" >
        <span slot="label" >
          <a-tooltip>
            {{$t('游记评审团身份')}}<template slot="title">{{$t('游记评审团.1评审团主席，2评委成员')}}</template>
          </a-tooltip>
        </span>
        <a-select :placeholder="$t('请选择')+$t('游记评审团身份')" v-model="form.type"  allowClear>
          <a-select-option v-for="(item, index) in typeList" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="avatar" >
        <span slot="label" >
          <a-tooltip>
            {{$t('游记评审团.头像')}}<template slot="title">{{$t('游记评审团头像')}}</template>
          </a-tooltip>
        </span>
        <OssUploadSingle v-model="form.avatar" type="img" :max-count="1" :prefix="study" :maxSize="200"></OssUploadSingle>

      </a-form-model-item>
      <a-form-model-item prop="name" >
        <span slot="label" >
          <a-tooltip>
            {{$t('游记评审团.姓名')}}<template slot="title">{{$t('游记评审团姓名')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.name" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('游记评审团姓名')" />
      </a-form-model-item>
      <a-form-model-item prop="introduce" >
        <span slot="label" >
          <a-tooltip>
            {{$t('游记评审团简介')}}<template slot="title">{{$t('游记评审团简介')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.introduce" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="imgs" >
        <span slot="label" >
          <a-tooltip>
            {{$t('游记评审团.图片')}}<template slot="title">{{$t('游记评审团.图片')}}</template>
          </a-tooltip>
        </span>
        <OssUploadSingle v-model="form.imgs" type="img" :max-count="9" :prefix="study" :maxSize="200"></OssUploadSingle>
      </a-form-model-item>
      <a-form-model-item prop="videoUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('游记评审团视频')}}<template slot="title">{{$t('游记评审团视频')}}</template>
          </a-tooltip>
        </span>
        <OssUploadSingle v-model="form.videoUrl" type="video" :max-count="9" :prefix="study" :maxSize="200"></OssUploadSingle>
      </a-form-model-item>

      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getNotesReview, addNotesReview, updateNotesReview } from '@/api/study/notesReview'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import OssUploadSingle from "@/components/OssUploadSingle";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    OssUploadSingle,
    CustomDictTag,
  },
  data () {
    return {
      typeList:[
        {type:1,name:"评审团主席"},
        {type:2,name:"评审团成员"}
      ],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        avatar: null,

        name: null,

        introduce: null,

        imgs: null,

        videoUrl: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: this.$t('评审团身份')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: this.$t('评审团头像')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('评审团头像')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ], introduce: [
          { required: true, message: this.$t('评审团头像')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],imgs: [
          { required: true, message: this.$t('评审团头像')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],videoUrl: [
          { required: true, message: this.$t('评审团头像')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        avatar: null,
        name: null,
        introduce: null,
        imgs: null,
        videoUrl: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getNotesReview({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateNotesReview(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addNotesReview(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
